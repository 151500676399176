@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'IRANSans';
  src: url('../public//font/YekanBakhFaNum-Regular.woff2') format('woff2');
}

body {
  font-family: 'IRANSans', sans-serif;
  direction: rtl;
}

*{
  font-family: 'IRANSans';
}

.ant-menu-item.ant-menu-item-selected{
  background-color:#ef1b47;
  color: #fff;

}