/* AdminSellerManagement.css */
.admin-seller-management {
    padding: 24px;
  }
  
  .admin-seller-management .ant-card {
    direction: rtl;
  }
  
  .admin-seller-management .ant-table {
    direction: rtl;
  }
  
  .admin-seller-management .ant-statistic {
    direction: rtl;
  }
  
  .admin-seller-management .ant-select {
    direction: rtl;
  }
  
  .admin-seller-management .ant-input {
    direction: rtl;
  }
  
  .admin-seller-management .ant-typography {
    margin-bottom: 24px;
  }
  
  /* RTL support for popconfirm */
  .ant-popover {
    direction: rtl;
  }